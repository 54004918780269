import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import bg1 from '../img/bg-1.png'
import bg2 from '../img/bg-2.png'
import bg3 from '../img/bg-3.png'
import bg4 from '../img/bg-4.png'
import bg5 from '../img/bg-5.png'
import bg6 from '../img/bg-6.png'
import bg7 from '../img/bg-7.png'

import "../styles/home.scss"

import Layout from "../components/Layout"

const bgImages = [
  bg1,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
  bg7,
]

export const HomeTemplate = ({ home, collectionIndex }) => {
  return (
    <>
      <div className="guidecards">
      {collectionIndex.map(({ title, label, slug, article, description }, idx) => (
        <a className="guidecard" href={slug} style={{
          backgroundImage: `url(${bgImages[idx]})`,
        }}>
          <p className="guidecard-label">{label}</p>
          <h4 className="guidecard-heading">{title}</h4>
          <p className="guidecard-description">
            {description}
          </p>
          <p className="guidecard-readmore">
            Read more
          </p>
        </a>
      ))}
      </div>
    </>
  )
}

class Home extends React.Component {
  render() {
    const { data } = this.props
    const {
      data: { footerData, navbarData },
    } = this.props
    const { frontmatter: home } = data.homePageData.edges[0].node
    const {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    } = home

    const collectionIndex = data.navbarData.edges.reduce(
      (result, { node: { frontmatter: { collection: navbarCollection, title, label, description } } }) => {
        const firstCollectionNode = data.allArticles.edges
          .find(({
            node: {
              fields: { collection },
              frontmatter: { index },
            }
          }) => navbarCollection === collection && index)

        if (!firstCollectionNode) {
          return result
        }

        result.push({
          title,
          label,
          description,
          slug: firstCollectionNode.node.fields.slug,
          article: firstCollectionNode.node.frontmatter.title,
        })
        return result
      },
      [])

    return (
      <Layout
        footerData={footerData}
        navbarData={navbarData}
      >
        <Helmet>
          <meta name="title" content={seoTitle} />
          <meta name="description" content={seoDescription} />
          <title>{browserTitle}</title>
        </Helmet>
        <HomeTemplate
          home={home}
          collectionIndex={collectionIndex}
        />
      </Layout>
    )
  }
}

Home.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default Home

export const pageQuery = graphql`
  query HomeQuery {
    ...LayoutFragment
    homePageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "Home" } } }) {
      edges {
        node {
          frontmatter {
            title
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
  }
`
